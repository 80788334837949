import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["triggerer", "elementToActUpon"];

  connect() {
    this.checkStatus();
  }

  checkStatus() {
    if (this.triggererTarget.checked) {
      this.elementToActUponTarget.value = "";
      this.elementToActUponTarget.disabled = true;
    } else {
      this.elementToActUponTarget.disabled = false;
    }
  }
}
