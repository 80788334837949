import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['selectInput', 'guarantorInput', 'tenantInput'];
  selectInputController;

  initialize() {
    this.selectInputController = this.selectInputTarget['form--collection-select--component'];
  }
  
  connect() {
    this.guarantorInputTarget.parentNode.parentNode.style.display = "none";
    this.tenantInputTarget.parentNode.parentNode.style.display = "none";
  }
  onSelectChange() {
    if (!this.getSelectInputController())
      return;
    this.guarantorInputTarget.parentNode.parentNode.style.display = "none";
    this.tenantInputTarget.parentNode.parentNode.style.display = "none";
    const selectedOption = this.getSelectInputController().value;

    if (selectedOption == "Tenant") {
      this.tenantInputTarget.parentNode.parentNode.style.display = "block";
    } else if (selectedOption == "Guarantor") {
      this.guarantorInputTarget.parentNode.parentNode.style.display = "block";
    }
  }

  getSelectInputController() {
    return this.selectInputController || this.selectInputTarget['form--collection-select--component']
  }
}
