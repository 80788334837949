import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["autocompleteComponent", "landlordList", "hiddenInput", "landlordIdsInput", "moralLandlordInput"];

  resultList = [];
  _autocompleteController = null;

  initialize() {
    this.onSelectResult = this.onSelectResult.bind(this);
    this.deleteResult = this.deleteResult.bind(this);
    this.form_name = this.element.dataset.formname;
    if (this.hiddenInputTarget.value) this.initializeResults();
  }

  connect() {
    this.autocompleteComponentTarget.addEventListener("selection", this.onSelectResult);
  }

  disconnect() {
    this.autocompleteComponentTarget.removeEventListener("selection", this.onSelectResult);
  }

  getAutocompleteController() {
    return this._autocompleteController || (this._autocompleteController = this.autocompleteComponentTarget["OQORO--autocomplete--component"]);
  }

  initializeResults() {
    const initialResults = JSON.parse(this.hiddenInputTarget.value);
    setTimeout(() => {
      initialResults.forEach((result) => {
        this.getAutocompleteController().selectResult(result);
      });
      this._updateLandlordIdsInputs(initialResults);
    }, 1);
  }

  onSelectResult(event) {
    const { detail: { resultList } } = event;
    this.resultList = resultList;
    this.updateResultsList();
    this.hiddenInputTarget.value = JSON.stringify(this.resultList);
    this._updateLandlordIdsInputs(this.resultList);
  }

  updateResultsList() {
    this.landlordListTarget.innerHTML = null;

    // On active ou désactive l'input de recherche de landlords si un MoralLandlord est sélectionné
    if (this.hasMoralLandlordInputTarget) {
      if (this.resultList.map((item) => item.type).includes("MoralLandlord")) {
        this.autocompleteComponentTarget.classList.add("autocomplete--disabled", this.autocompleteComponentTarget.classList.contains("autocomplete--disabled"));
        this.moralLandlordInputTarget.value = true;
      } else {
        this.autocompleteComponentTarget.classList.remove(
          "autocomplete--disabled",
          this.autocompleteComponentTarget.classList.contains("autocomplete--disabled")
        );
        this.moralLandlordInputTarget.value = false;
      }
    }

    if (this.resultList.length > 0) {
      this.landlordListTarget.classList.remove("hidden");
      this.resultList.forEach((item, i) => {
        const list = document.createElement("li");
        const label = document.createElement("span");
        const icon_name_wrapper = document.createElement("div");
        const name = document.createElement("span");
        const icon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        const deleteBtn = document.createElementNS("http://www.w3.org/2000/svg", "svg");

        icon_name_wrapper.classList.add("landlord-list__icon_name_wrapper");
        label.innerHTML = `Propriétaire ${i + 1}`;
        name.innerHTML = item.name;
        label.classList.add("landlord-list__label");
        name.classList.add("landlord-list__name");
        deleteBtn.classList.add("cursor-pointer");

        icon.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        const path1 = document.createElementNS("http://www.w3.org/2000/svg", "path");
        if (item.type === "MoralLandlord") {
          // SVG Icône appartement
          icon.setAttribute("viewBox", "0 96 960 960");
          path1.setAttribute(
            "d",
            "M120 936V381h165V216h390v330h165v390H533V771H427v165H120Zm60-60h105V771H180v105Zm0-165h105V606H180v105Zm0-165h105V441H180v105Zm165 165h105V606H345v105Zm0-165h105V441H345v105Zm0-165h105V276H345v105Zm165 330h105V606H510v105Zm0-165h105V441H510v105Zm0-165h105V276H510v105Zm165 495h105V771H675v105Zm0-165h105V606H675v105Z"
          );
          icon.appendChild(path1);
        } else {
          // SVG Icône user
          icon.setAttribute("fill", "none");
          icon.setAttribute("viewBox", "0 0 24 24");
          icon.setAttribute("stroke", "currentColor");
          icon.setAttribute("stroke-width", "2");
          path1.setAttribute("stroke-linecap", "round");
          path1.setAttribute("stroke-linejoin", "round");
          path1.setAttribute("d", "M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z");
        }
        icon.appendChild(path1);

        // SVG Bouton "fermer"
        deleteBtn.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        deleteBtn.setAttribute("fill", "none");
        deleteBtn.setAttribute("viewBox", "0 0 24 24");
        deleteBtn.setAttribute("stroke", "currentColor");
        const path2 = document.createElementNS("http://www.w3.org/2000/svg", "path");
        path2.setAttribute("stroke-linecap", "round");
        path2.setAttribute("stroke-linejoin", "round");
        path2.setAttribute("stroke-width", "2");
        path2.setAttribute("d", "M6 18L18 6M6 6l12 12");
        deleteBtn.appendChild(path2);
        deleteBtn.addEventListener("click", (e) => {
          e.preventDefault();
          this.deleteResult(item);
        });

        icon_name_wrapper.append(icon);
        icon_name_wrapper.append(name);
        list.insertAdjacentElement("beforeend", label);
        list.insertAdjacentElement("beforeend", icon_name_wrapper);
        list.insertAdjacentElement("beforeend", deleteBtn);
        this.landlordListTarget.insertAdjacentElement("beforeend", list);
      });
    } else {
      this.landlordListTarget.classList.add("hidden");
    }
  }

  deleteResult(item) {
    this.getAutocompleteController().deleteResult(item);
  }

  _updateLandlordIdsInputs(results) {
    let field_name = null;
    if (this.hasMoralLandlordInputTarget) {
      field_name = this.moralLandlordInputTarget.value == 'true' ? 'moral_landlord_ids' : 'landlord_ids' ;
    } else {
      field_name = 'landlord_ids';
    }
    this.landlordIdsInputTarget.innerHTML = "";
    results.forEach((res) => {
      let hiddenField = document.createElement("input");
      hiddenField.setAttribute("multiple", "multiple");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", `${this.form_name}[${field_name}][]`);
      hiddenField.value = res.id;
      this.landlordIdsInputTarget.append(hiddenField);
    });
  }
}
