import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectInput", "descriptionWrapper", "descriptionContent"];
  static values = { descriptions: String }
  selectInputController;

  initialize() {
    this.selectInputController = this.selectInputTarget['form--collection-select--component'];
    this.onSelectChange = this.onSelectChange.bind(this);

    if (this.selectInputTarget.value !== "") this.onSelectChange();
  }

  onSelectChange() {
    if (!this.getSelectInputController())
      return;
    const selectedOption = +this.getSelectInputController().value;
    const descriptionsArray = JSON.parse(this.descriptionsValue) || [];

    const selectedItem = descriptionsArray.find(item => item.id === selectedOption)
    if (this.descriptionWrapperTarget.classList.contains("hidden")) this.descriptionWrapperTarget.classList.remove("hidden");
    this.descriptionContentTarget.textContent = selectedItem?.description || "Aucune option sélectionnée";
  }

  getSelectInputController() {
    return this.selectInputController || this.selectInputTarget['form--collection-select--component']
  }
}
