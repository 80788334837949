import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["periodInput", "customPeriodInput", "customPeriodInputError"];

  _forms = document.querySelectorAll("form[data-group='formsForMerge']");
  _startMonthInput = this.element.querySelector("[name='filter[period][start][month]']");
  _startYearInput = this.element.querySelector("[name='filter[period][start][year]']");
  _endMonthInput = this.element.querySelector("[name='filter[period][end][month]']");
  _endYearInput = this.element.querySelector("[name='filter[period][end][year]']");

  initialize() {
    this.periodInputTarget.addEventListener("change", this.onPeriodInputChange.bind(this));
    this.customPeriodInputTargets.forEach(
      ((input) => {
        input.addEventListener("change", this.onCustomPeriodInputChange.bind(this));
      }).bind(this)
    );
    this.mergeForms = this.mergeForms.bind(this);
  }

  connect() {
    this._forms.forEach(
      ((f) => {
        if (f.dataset.name !== "propertiesAndPeriodForm") f.addEventListener("submit", this.mergeForms);
      }).bind(this)
    );
  }

  disconnect() {
    this._forms.forEach(
      ((f) => {
        if (f.dataset.name !== "propertiesAndPeriodForm") f.removeEventListener("submit", this.mergeForms);
      }).bind(this)
    );
  }

  onCustomPeriodInputChange(event) {
    this.customPeriodInputTargets.forEach((input) => {
      input.classList.remove("is-danger");
    });
    this.customPeriodInputErrorTarget.classList.add("hidden");

    const input = event.target;

    // Check dates
    const startDate = new Date(
      this.element.querySelector("[name='filter[period][start][year]']").value,
      this.element.querySelector("[name='filter[period][start][month]']").value
    );
    const endDate = new Date(
      this.element.querySelector("[name='filter[period][end][year]']").value,
      this.element.querySelector("[name='filter[period][end][month]']").value
    );

    this.periodInputTarget.value = "custom";

    if (startDate > endDate) {
      this.customPeriodInputTargets.forEach((input) => {
        input.classList.add("is-danger");
      });
      this.customPeriodInputErrorTarget.classList.remove("hidden");
      return false;
    }

    input.form.requestSubmit();
  }

  onPeriodInputChange(event) {
    const input = event.target;
    if (input.value == "6m") {
      this.resetPeriodInputs();
      input.form.requestSubmit();
    }
  }

  mergeForms(e) {
    if (e) e.preventDefault();
    // On récupère les données du form period pour les assigner au nouveau form
    const newForm = document.querySelector("form[data-name='propertiesAndPeriodForm']");
    const newPeriodType = newForm.querySelector("#newPeriodType");
    const newStartMonth = newForm.querySelector("#newStartMonth");
    const newStartYear = newForm.querySelector("#newStartYear");
    const newEndMonth = newForm.querySelector("#newEndMonth");
    const newEndYear = newForm.querySelector("#newEndYear");

    newPeriodType.value = this.periodInputTarget.value;
    newStartMonth.value = this._startMonthInput.value;
    newStartYear.value = this._startYearInput.value;
    newEndMonth.value = this._endMonthInput.value;
    newEndYear.value = this._endYearInput.value;

    // On clean les potentiels inputs propriétés pré-existants
    let oldInputs = newForm.querySelectorAll("input[name='filter[properties][]']");
    if (oldInputs) {
      oldInputs.forEach((element) => {
        element.remove();
      });
    }
    // On récupère les données du form properties pour les assigner au nouveau form
    const oldForm = document.querySelector("form[data-name='propertiesForm']");
    const oldFormData = new FormData(oldForm);
    const values = [...oldFormData.entries()].flat().filter((element) => element !== "filter[properties][]" && !element.startsWith("blank") && element !== "");

    values.forEach((element) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.value = element;
      input.name = "filter[properties][]";
      newForm.appendChild(input);
    });

    newForm.requestSubmit();
    setTimeout(() => {
      oldForm.querySelector("#submitButtonPropertyForm").disabled = false;
    }, 300);
  }

  resetPeriodInputs() {
    let today = new Date();
    let sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 5, today.getDate());

    this._startMonthInput.value = sixMonthsAgo.getMonth() + 1 > 12 ? 1 : sixMonthsAgo.getMonth() + 1;
    this._startYearInput.value = sixMonthsAgo.getFullYear();
    this._endMonthInput.value = today.getMonth() + 1 > 12 ? 1 : today.getMonth() + 1;
    this._endYearInput.value = today.getMonth() + 1 > 12 ? today.getFullYear() + 1 : today.getFullYear();
  }
}
