import {Controller} from "@hotwired/stimulus"
import { template } from "lodash";

export default class extends Controller {

  static targets = ['propertyDocumentDropzone', 'fileTemplate', 'list', 'modal', 'documentTypeInput']
  static values = {
    deleteUrl: String,
  };

  _fileTemplate = null;
  _index = 0;
  _dropzoneController = null;
  _form = null;

  connect() {
    this._fileTemplate = template(this.fileTemplateTarget.innerHTML);
    this._form = this.element.closest("form");
  }

  submitDocumentsModal() {
    if (!this._dropzoneController) {
      this._dropzoneController = this.application.getControllerForElementAndIdentifier(this.propertyDocumentDropzoneTarget, "dropzone--component");
    }

    if (this.documentTypeInputTarget.value === '' || this.documentTypeInputTarget.value === null) {
      this.createErrorMessageMissingType();
      return;
    }

    if (!this.hasOnlyOneTypeOfDocument()) {
      this.createErrorMessageMultipleType();
      return;
    }

    this._dropzoneController.uploadedFiles.forEach((f) => {
      this.addUploadedFileFromModal(f);
    });
    this.create_property_document_type_field();
    this._index++;
    // Reset form and close modal
    this.modalTarget.querySelector('.modal__close-btn').click();
    this.reset_fields();
  }

  resetFieldError() {
    if (this.documentTypeInputTarget.value !== '' && this.documentTypeInputTarget.value !== null) {
      let errorField = this._form.querySelector('span#documentTypeError');
      if (errorField)
        errorField.remove();
    }
  }

  addUploadedFileFromModal(f) {
    const item = this.createDocumentItem(f.file, f.signed_id);
    this.listTarget.appendChild(item);
    // Delete hidden items créés par le dropzone controller pour ajouter l'index.
    const input = this._dropzoneController._form.querySelector(`input[value="${f.signed_id}"]`);
    input.remove();
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("value", f.signed_id);
    hiddenField.name = `property[property_documents][${this._index}][files][]`;
    this._form.appendChild(hiddenField);
  }

  createDocumentItem(file, signed_id) {
    let selectedOption = this.documentTypeInputTarget.options[this.documentTypeInputTarget.selectedIndex];
    const itemData = {
      signedId: typeof signed_id !== "undefined" && signed_id ? signed_id : "",
      publicUid: typeof file.public_uid !== "undefined" && file.public_uid ? file.public_uid : "",
      filename: file.name,
      documentType: selectedOption.text,
      documentTypeId: selectedOption.text,
      typeInputName: `property[property_documents][${this._index}][type]`,
      filesInputName: `property[property_documents][${this._index}][files][]`
    };
    const item = this.createElementFromHTML(this._fileTemplate(itemData));
    return item;
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  reset_fields() {
    let selectElem = this.documentTypeInputTarget;
    let errorField = this._form.querySelector('span#documentTypeError');
    selectElem.options[selectElem.selectedIndex].selected = false;
    this._dropzoneController.listTarget.innerHTML = '';
    if (errorField)
      errorField.remove();
  }

  create_property_document_type_field() {
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("value", this.documentTypeInputTarget.value);
    hiddenField.name = `property[property_documents][${this._index}][type]`;
    this._form.appendChild(hiddenField);
  }

  createErrorMessageMissingType() {
    const errorElem = document.createElement("span");
    errorElem.setAttribute("id", "documentTypeError");
    errorElem.classList.add("text-danger", "text-sm", "font-medium");
    errorElem.innerHTML = "Veuillez sélectionner un type de document dans la liste.";
    this.documentTypeInputTarget.after(errorElem);
  }

  createErrorMessageMultipleType() {
    const errorElem = document.createElement("span");
    errorElem.setAttribute("id", "documentTypeError");
    errorElem.classList.add("text-danger", "text-sm", "font-medium");
    errorElem.innerHTML = "Un document de ce type est déjà annexé.";
    this.documentTypeInputTarget.after(errorElem);

  }

  hasOnlyOneTypeOfDocument() {
    if (this._form.querySelectorAll(`div.property-document-item[data-document-type-id="${this.documentTypeInputTarget.value}"]`).length == 0)
      return true;
    else
      return false;
  }

  deleteFile(e) {
    const item = e.currentTarget.closest(".list__item");

    const signed_id = item.dataset.signedId;
    const statusText = item.querySelector(".status-text");
    statusText.innerHTML = "Suppression en cours...";
    item.classList.add("deleting");
    
    if (this.deleteUrlValue) {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content
      fetch(this.deleteUrlValue, {
        method: "delete",
        body: JSON.stringify({ signed_id: signed_id }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        credentials: "same-origin",
      })
        .then((response) => { })
        .then((_data) => {
          var itemHiddenInput = this._form.querySelector(`input[name="${item.dataset.filesInputName}"][value="${signed_id}"]`)
          if (itemHiddenInput && typeof(itemHiddenInput) !== 'undefined')
            itemHiddenInput.remove();
          if (item.dataset.filesInputName && this._form.querySelectorAll(`input[name="${item.dataset.filesInputName}"]`).length == 0) {
            this._form.querySelector(`input[name="${item.dataset.typeInputName}"]`).remove();
          }
          item.remove();
        });
    }
  }
}
