import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["leaseRow", "leaseStart", "leaseEnd", "formerTenants", "checkboxFilter"];

  lastYearsDate = new Date(new Date().getFullYear() - 1, 0, 1);
  accordion = this.element.querySelector(".oq-accordion");

  initialize() {
    this.toggleFilter = this.toggleFilter.bind(this);
  }

  connect() {
    this.checkboxFilterTarget.addEventListener("change", this.toggleFilter);
  }

  disconnect() {
    this.checkboxFilterTarget.removeEventListener("change", this.toggleFilter);
  }

  dateFormatter(date) {
    if (date === undefined) return false;
    const dateArray = date.split(/\D+/);
    const dateObject = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
    dateObject.setFullYear(dateArray[2]);
    return dateObject;
  }

  rotateAccordionIcon() {
    const arrow = this.accordion.querySelector("svg:first-of-type");
    this.accordion.classList.contains("open") ? (arrow.style = "") : (arrow.style.transform = "rotate(180deg)");
  }

  toggleFilter() {
    // On cache les rows hors période
    this.leaseRowTargets.map(
      ((row, i) => {
        if (
          !(
            this.dateFormatter(row.dataset.leaseStart) < this.lastYearsDate &&
            (row.dataset.leaseEnd === "" || this.dateFormatter(row.dataset.leaseEnd) > this.lastYearsDate)
          )
        ) {
          this.checkboxFilterTarget.checked ? row.classList.add("hidden") : row.classList.remove("hidden");
        }
      }).bind(this)
    );

    // On cache l'accordéon et on affiche la div qui contient les anciens locataires
    if (this.checkboxFilterTarget.checked) {
      this.accordion.classList.add("hidden");
      this.formerTenantsTarget.classList.remove("hidden");
    } else {
      this.accordion.classList.remove("hidden");
      this.formerTenantsTarget.classList.add("hidden");
    }
  }
}
