import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["landlordCommentInput", "tenantCommentWrapper"]

  generateComment() {
    var tenantComments = this.tenantCommentWrapperTarget.querySelectorAll('[data-tenant-comment-input]');
    var generatedComment = "";
    tenantComments.forEach(element => {
      if (element.textContent.length > 0) {
        generatedComment += element.textContent.trim();
        generatedComment += "\n";
      }
    });
    this.landlordCommentInputTarget.value = generatedComment;
  }
}
