import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['inputMontantRegul', 'inputTropPercuRegul', 'validateButton', 'modifyButton'];

  toggleInput() {
    this.inputMontantRegulTarget.disabled = false;
    this.inputTropPercuRegulTarget.disabled = false;
    this.validateButtonTarget.classList.remove("hidden");
    this.modifyButtonTarget.classList.add("hidden");
  }

  disableInputTropPercuRegulInput() {
    this.inputTropPercuRegulTarget.value = "0.00";
  }

  disableInputMontantRegulInput() {
    this.inputMontantRegulTarget.value = "0.00";
  }
}
