import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "promo"
  ];

  initialize() {
  }

  show_promo_input(event) {
    this.promoTarget.classList.remove('hidden');
    this.promoTarget.classList.add('flex');
    event.preventDefault();
  }
}
