import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["previousButton", "nextButton", "slidingContent"];

  next(e) {
    e.preventDefault();
    e.stopPropagation();
    // Disable/activate the right button
    this.nextButtonTarget.classList.toggle("button--cta-disabled");
    this.previousButtonTarget.classList.toggle("button--cta-disabled");

    // Adding/removing the translate class
    this.slidingContentTargets.forEach((element) => {
      element.classList.toggle("-translate-x-1/2");
    });
  }

  previous(e) {
    e.preventDefault();
    e.stopPropagation();
    // Disable/activate the right button
    this.previousButtonTarget.classList.toggle("button--cta-disabled");
    this.nextButtonTarget.classList.toggle("button--cta-disabled");

    // Adding/removing the translate class
    this.slidingContentTargets.forEach((element) => {
      element.classList.toggle("-translate-x-1/2");
    });
  }
}
