import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fiscalNumberForm", "fiscalNumberDisplay", "ibanDisplay", "ibanForm", "fiscalNumberValueInput", "ibanValueInput", "fiscalNumberValueSpan", "ibanValueSpan"];
  static values = {
    fiscalNumberInitial: String,
    ibanInitial: String,
  }

  switch(event) {
    const { params } = event;
    const origin = params.origin;
    this[origin + "DisplayTargets"].forEach((target) => {
      target.classList.add("hidden");
    });
    this[origin + "FormTargets"].forEach((target) => {
      target.classList.remove("hidden");
    });
  }

  cancel(event) {
    const { params } = event;
    const origin = params.origin;
    const initialValue = this[origin + "InitialValue"];
    this[origin + "DisplayTargets"].forEach((target) => {
      target.classList.remove("hidden");
    });
    this[origin + "FormTargets"].forEach((target) => {
      target.classList.add("hidden");
    });
    this[origin + "ValueSpanTarget"].textContent = initialValue === "" ? "--" : origin === "iban" ? this.obfuscateIban(initialValue) : initialValue;
    if (origin === "iban") {
      this.ibanValueInputTarget["form--iban-field--component"].setValue(initialValue);
    } else if (origin === "fiscalNumber") {
      this.fiscalNumberValueInputTarget.value = initialValue;
    }
  }

  obfuscateIban(iban_str) {
    if (!iban_str || typeof iban_str !== "string" || iban_str.trim() === "") {
        return "";
    }

    const firstFour = iban_str.slice(0, 4);
    const lastTwo = iban_str.slice(-2);
    const maskedPart = "X".repeat(6)
    const obfuscatedIban = `${firstFour} ${maskedPart} ${lastTwo}`;

    return obfuscatedIban;
  }
}
