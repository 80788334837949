import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nameInput", "emailInput", "phoneInput", "checkbox", "submitButton"];

  // Ce controller devra être supprimé quand le oqoro_form gèrera les cas marginaux
  // cf https://github.com/monbelappart/monbelappart-rails/issues/6649

  connect() {
    this.checkFormValidity();
  }

  checkFormValidity() {
    let isValid =
      this.nameInputTarget.value.trim().length > 0 &&
      this.isValidEmail(this.emailInputTarget.value.trim()) &&
      this.phoneInputTarget.value.trim().length > 0 &&
      this.checkboxTarget.checked;
    this.submitButtonTarget.disabled = !isValid;
  }

  isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
}
