import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "loyerRange" ,
        "loyerText",
        "economiesText",
        "melMbaText",
        "gestionMbaText",
        "totalMbaText",
        "melAgenceText",
        "gestionAgenceText",
        "totalAgenceText"
    ]
    connect() {
        this.loyerRangeTarget.addEventListener("input", (() => {
            this.update()
        }).bind(this))

        this.update()
    }
    toFixedIfNecessary( value, dp ){
        return +parseFloat(value).toFixed( dp );
    }

    update() {
        let loyer = this.loyerRangeTarget.value
        let melRate = 0.5
        if (Date.now() >= Date.parse("01.01.2024")) {
          melRate = 0.75
        }
        let melMba = this.loyerRangeTarget.value * melRate
        let gestionMba = Math.round(loyer * 4.9) / 100 * 12
        let totalMba = Math.round((melMba + gestionMba)*100)/100
        let melAgence =  loyer * 1
        let gestionAgence = Math.round(loyer * 7.5) / 100 * 12
        let totalAgence = gestionAgence + melAgence
        let economies = Math.round(totalAgence - totalMba);
        this.loyerTextTarget.innerHTML = loyer
        this.melMbaTextTarget.innerHTML = this.toFixedIfNecessary(melMba,2)
        this.gestionMbaTextTarget.innerHTML = this.toFixedIfNecessary(gestionMba,2)
        this.totalMbaTextTarget.innerHTML = this.toFixedIfNecessary(totalMba,2)
        this.melAgenceTextTarget.innerHTML = this.toFixedIfNecessary(melAgence,2)
        this.gestionAgenceTextTarget.innerHTML = this.toFixedIfNecessary(gestionAgence,2)
        this.totalAgenceTextTarget.innerHTML = this.toFixedIfNecessary(totalAgence,2)
        this.economiesTextTargets.forEach(
            element => element.innerHTML = economies
        );
    }
}
