import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['editIbanSection', 'showIbanSection'];

  showUpdateIbanBtn() {
    this.editIbanSectionTarget.classList.remove("hidden");
    this.showIbanSectionTarget.classList.add("hidden");
  }

  hideUpdateIbanBtn() {
    this.editIbanSectionTarget.classList.add("hidden");
    this.showIbanSectionTarget.classList.remove("hidden");
  }
}
