import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "frame"
    ]
    client = null;

    initialize() {
        this.client = ZAFClient.init();
        this.client.invoke('resize', {width: '100%', height: '800px'});
        this.getContext()

        document.addEventListener('turbo:submit-start', function (event) {
            const submitter = event.detail.formSubmission.submitter
            if (submitter) {
                submitter.form.innerHTML = '<div class="py-5"><b>En-cours de sauvegarde...</b></div>'
            }
        });

    }

    getContext() {
        this.client.get('ticket').then((async function (data) {
            // Récupérer l'ID du probleme lié à l'incident s'il existe
            let problemId
            let tickeTypeObj = await this.client.get('ticket.type')
            if (tickeTypeObj['ticket.type'] === 'incident') {
                let problemObj = await this.client.get('ticket.customField:problem_id')
                problemId = problemObj['ticket.customField:problem_id'];
            }

            // Id du ticket courant
            let ticketId = data.ticket.id;

            let userEmails = data.ticket.requester.identities.filter(identity => identity.type == "email").map(o => o["value"])

            // IMPORTANT : En contexte, on veut le problème lié à l'incident s'il existe, sinon on prend le ticket courant
            let contextTicketId = problemId || ticketId
            this.frameTarget.src = `/zendesk_app/sidebar/context?emails=${encodeURIComponent(userEmails)}&ticket_id=${encodeURIComponent(contextTicketId)}&problem_id=${encodeURIComponent(problemId)}`;
        }).bind(this))
    }
}
