// import {Controller} from "@hotwired/stimulus"
import NestedAssociation from "./nested_association_controller.js";
import { template } from "lodash";

export default class extends NestedAssociation {
  static targets = ["template", "wrapper", "roomTemplate", "roomsList"];

  _fileTemplate = null;

  connect() {
    this.update_indexes();
    if (this.minValue > 0) {
      for (let i = 0; i < this.minValue; i++) this.add_association();
    }
    this._fileTemplate = template(this.roomTemplateTarget.innerHTML);
    const nested_loyer_rows = Array.prototype.slice.call(
      this.element.querySelectorAll('div.lease-nested-association.nested-association:not([data-deleted="true"])')
    );
    nested_loyer_rows.forEach((el) => {
      this.updateTenantsLoyer(el.querySelector(".lot-item"));
    });
  }

  add_association(event) {
    super.add_association(event);
    const elem = this.getAddedElem();

    // Renseigner le random ID sur les élements qui s'en servent
    const elemRoomId = this.hex(6);
    elem.querySelector(".lot-item").setAttribute("data-room-id", elemRoomId);
    elem.querySelector(".montant-loyer-hc").dataset.roomRentId = `roomRentId-${elemRoomId}`;
    elem.querySelector(".montant-charges").dataset.roomRentId = `roomRentId-${elemRoomId}`;

    this.addLotRoom(elemRoomId, this.indexValue);
  }

  hex(n) {
    n = n || 6;
    var result = "";
    while (n--) {
      result += Math.floor(Math.random() * 16).toString(16);
    }
    return result;
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();

    return div.firstChild;
  }

  addLotRoom(elemRoomId, index) {
    const itemData = {
      index: index,
      roomId: elemRoomId,
    };
    const item = this.createElementFromHTML(this._fileTemplate(itemData));
    this.roomsListTarget.appendChild(item);
  }

  remove_all_associations() {
    const allAddedNestedAssociations = this.element.querySelectorAll(
      '.lease-nested-association-wrapper .nested-association:not([data-deleted="true"]):not(:first-child)'
    );

    if (allAddedNestedAssociations.length > 0) {
      allAddedNestedAssociations.forEach(
        ((el) => {
          this.remove_child(el);
        }).bind(this)
      );
      this._count = 0;
    }
  }

  remove_child(child) {
    super.remove_child(child);
    this.removeRoom(child);
  }

  updateTenantsLoyer(e) {
    let parent_row, elem;

    if (typeof e.target !== "undefined") {
      elem = e.target;
      parent_row = elem.closest(".lot-item");
    } else {
      elem = e.querySelector(".rent-loyer-montant-input");
      parent_row = e;
    }
    let montant_loyer_hc = parent_row.querySelector(".montant-loyer-hc").value;
    let montant_charges = parent_row.querySelector(".montant-charges").value;
    montant_loyer_hc = isNaN(montant_loyer_hc) || !montant_loyer_hc ? 0 : parseFloat(montant_loyer_hc);
    montant_charges = isNaN(montant_charges) || !montant_charges ? 0 : parseFloat(montant_charges);
    const total = montant_loyer_hc + montant_charges;

    this.element.querySelector(`#${elem.dataset.roomRentId}`).textContent = total;
  }

  removeRoom(elem) {
    let item = elem.querySelector(".lot-item").dataset.roomId;
    this.element.querySelector(`div#roomTenants-${item}`).remove();
  }

  update_indexes() {
    const associations = this.element.querySelectorAll(".nested-association");
    let index = 0;
    associations.forEach(
      ((elem) => {
        if (elem.querySelector("input[name*='_destroy']") && elem.querySelector("input[name*='_destroy']").value == 1) return;
        let content = elem.innerHTML;
        if (content.indexOf("NEW_INDEX") > -1) {
          content = content.replace(/NEW_INDEX_1/g, index + 1);
          content = content.replace(/NEW_INDEX/g, index);
          elem.innerHTML = content;
        }

        elem.querySelectorAll(".nested-association__index").forEach((el) => {
          el.innerText = index;
        });
        elem.querySelectorAll(".nested-association__index-1").forEach((el) => {
          el.innerText = index + 1;
        });
        if (elem.querySelector(".lot-item")) {
          var room_id = elem.querySelector(".lot-item").dataset.roomId;
          var roomTenantElem = this.element.querySelector(`div#roomTenants-${room_id}`);
          // Replace lots tenants_field index
          let reg = /property\[activation_data\]\[lots\]\[\d+\]\[tenants\](.*)/;
          roomTenantElem.querySelectorAll('input[name^="property[activation_data][lots]"][name*="[tenants]"]').forEach((tenant_elem) => {
            let current_attribute = tenant_elem.getAttribute("name");
            let new_attribute = current_attribute.replace(reg, `property[activation_data][lots][${index + 1}][tenants]$1`);
            tenant_elem.setAttribute("name", new_attribute);
          });
          const hiddenPublicUidInput = roomTenantElem.querySelector('input[name^="property[activation_data][lots]"][name*="[public_uid]"]');
          if (hiddenPublicUidInput) {
            reg = /property\[activation_data\]\[lots\]\[\d+\]\[public_uid\](.*)/;
            let current_attribute = hiddenPublicUidInput.getAttribute("name");
            let new_attribute = current_attribute.replace(reg, `property[activation_data][lots][${index + 1}][public_uid]$1`);
            hiddenPublicUidInput.setAttribute("name", new_attribute);
          }
          roomTenantElem.querySelector(`.room-index`).innerHTML = index + 1;
        }
        index++;
      }).bind(this)
    );
  }

  clearFields(e) {
    const parentElement = e.currentTarget.closest("[id^='roomTenants-']");
    const roomsToDelete = parentElement.querySelectorAll(".nested-association:not(:first-child)");
    const fieldsToClear = parentElement.querySelectorAll("[id^=property_activation_data_attributes_lots_tenants]");

    // Timeout pour matcher la vitesse de fermeture de l'accordéon
    setTimeout(() => {
      // Clear the additional blocks
      roomsToDelete.forEach((element) => {
        super.remove_child(element);
      });
      // Clear the fields
      fieldsToClear.forEach((element) => {
        if (element.value !== "") {
          element.value = "";
        }
      });
    }, 300);
  }
}
