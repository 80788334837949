import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filterForm"]

  prefillFilters(e) {
    if (!this.filterFormTarget)
      return false;
    const data = new FormData(this.filterFormTarget);
    let d = {};
    for (const pair of data.entries()) {
      const key = pair[0];
      const value = pair[1];
      if (key == 'scope' || key.endsWith("[order]") || key.endsWith("[descending]") || !value)
        continue;
      // convert key
      // ex: applications_datagrid[begin_date][to] => begin_date[to]
      let keyclean = key.substring(key.split('[')[0].length)
      keyclean = keyclean.substring(1, keyclean.split(']')[0].length) + keyclean.substring(keyclean.split(']')[0].length +1)
      if (keyclean == "search")
        continue;
      if (key.endsWith("[]")) {
        keyclean = keyclean.substring(0, keyclean.length - 2);
        if (!d[keyclean])
          d[keyclean] = [];
        d[keyclean].push(value);
      } else {
        d[keyclean] = value;
      }
    }
    const json = JSON.stringify(d);
    document.querySelector("[data-modal--component-name-value='custom-filter-view-new']").querySelector("[name='filter[filters]']").value = json
  }
}
