import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggler"];

  connect() {
    const current_year = new Date().getFullYear();
    let latest_toggle_year_elem = document.querySelector("div#landlord-documents-year-wrapper-" + current_year);
    if (latest_toggle_year_elem) {
      latest_toggle_year_elem.querySelector(".documents-year-toggler").click();
    }
  }

  handleYearToggler(e) {
    let parent = e.target.closest(".landlord-document-year-wrapper");
    if (parent.querySelectorAll(".landlord-documents-wrapper").length == 0) {
      parent.querySelector(".spinner-container").style.display = "block";
      parent.querySelector(".toggler-chevron").style.transform = "rotate(180deg)";
    } else {
      if (parent.classList.contains("active")) {
        parent.classList.remove("active");
        parent.querySelector(".landlord-documents-wrapper").style.display = "none";
        parent.querySelector(".toggler-chevron").style.transform = "rotate(0deg)";
      } else {
        parent.classList.add("active");
        parent.querySelector(".landlord-documents-wrapper").style.display = "block";
        parent.querySelector(".toggler-chevron").style.transform = "rotate(180deg)";
      }
      e.preventDefault();
    }
  }
}
