import NestedAssociation from "./nested_association_controller.js";

export default class extends NestedAssociation {
  clickNo({ currentTarget }) {
    let form = currentTarget.closest(".lot-form");
    let input = form.querySelector('input[name*="has_loc"]');
    input.value = "false";
    this.clearFields(currentTarget);
  }

  clickYes({ currentTarget }) {
    let form = currentTarget.closest(".lot-form");
    let input = form.querySelector('input[name*="has_loc"]');
    input.value = "true";
  }

  clearFields(btn) {
    const parentElement = btn.closest(".lot-tenant-form");
    const roomsToDelete = parentElement.querySelectorAll(".nested-association:not(:first-child)");
    const fieldsToClear = parentElement.querySelectorAll("[id^=property_activation_data_lots_tenants]");

    // Timeout pour matcher la vitesse de fermeture de l'accordéon
    setTimeout(() => {
      // Clear the additional blocks
      roomsToDelete.forEach((element) => {
        super.remove_child(element);
      });
      // Clear the fields
      fieldsToClear.forEach((element) => {
        if (element.value !== "") {
          element.value = "";
        }
      });
    }, 300);
  }
}
