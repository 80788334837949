import { Controller } from "@hotwired/stimulus"
import confetti from 'canvas-confetti';

export default class extends Controller {
    static targets = [
        "inviteBtn",
        "gifCheckModal",
        "gifApplauseModal"
    ]

    onInviteSuccess(event) {
        const { detail: { success } } = event
        if(success) {
            this.show(this.gifCheckModalTarget);
            this.inviteBtnTarget.removeAttribute("disabled");
            setTimeout(() => {
                if (this.isShowed(this.gifCheckModalTarget)) {
                    this.hide(this.gifCheckModalTarget);
                    this.show(this.gifApplauseModalTarget);
                    setTimeout(() => {
                        if(this.isShowed(this.gifApplauseModalTarget)) {
                            confetti({particleCount: 200, ticks: 150})
                        }
                    }, 2000, );
                    setTimeout(() => {
                        this.hide(this.gifApplauseModalTarget);
                    }, 4000)
                }
            }, 2000)
        }
    }

    connect() {
    }

    isShowed(element) {
        return element.classList.contains('open');
    }

    hide(element) {
        element.classList.add('invisible', 'opacity-0');
        element.classList.remove('opacity-1', 'open');
    }

    show(element) {
        element.classList.remove('invisible', 'opacity-0');
        element.classList.add('opacity-1', 'open');
        const check = element.querySelector("#gif_check");
        const clap = element.querySelector("#gif_clap");
        this.restart_gif(check);
        this.restart_gif(clap);
    }

    restart_gif(gif) {
        if (gif) {
            const url = gif.src;
            gif.src = "";
            gif.src = url;
        }
    }

}
