import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["menu"]
    dropdown = null
    isOpen = false
    hasEventListener = false

    connect() {
        this.dropdown = this.element
        this.menuTarget.classList.add("hidden")
    }

    toggle(e) {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        this.menuTarget.classList.toggle("hidden")
        this.dropdown.classList.toggle("open")
        this.isOpen = !this.isOpen
        if (this.isOpen && !this.hasEventListener) {
            window.addEventListener('click', this.closeOnClickOutside.bind(this))
            this.hasEventListener = true
        }
    }

    open() {
        this.menuTarget.classList.remove("hidden")
        this.dropdown.classList.add("open")
        this.isOpen = true
        if (this.isOpen && !this.hasEventListener) {
            window.addEventListener('click', this.closeOnClickOutside.bind(this))
            this.hasEventListener = true
        }
    }

    closeOnClickOutside = (event) => {
        let dropdown = event.target.closest('.dropdown');
        if (!dropdown || dropdown.querySelector('.dropdown-menu') != this.menuTarget) {
            if (this.isOpen)
                this.toggle()
            window.removeEventListener('click', this.closeOnClickOutside.bind(this))
            this.hasEventListener = false
        }
    }
}
