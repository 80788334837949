import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["totalLoyer", "totalCharge", "totalLoyerCharge", "newLease", "planNumerotation", "leaseTable"];
  static values = { partenaire: String };

  partenaireContext = this.hasPartenaireValue ? true : false;

  connect() {
    this.form_name = this.element.dataset.form_name;
    this.update_total();
    this.update_nb_mois_options();
    this.init_one_or_multiple_lease();
    this.update_type_charges_locatives();
    this.toggle_plan_numerotation()
  }

  update_total() {
    const loyer_inputs = Array.prototype.slice.call(
      this.element.querySelectorAll(
        `div.nested-association:not([data-deleted="true"]) input[name*="[montant_du_loyer]"]`
      )
    );
    const total_loyers = loyer_inputs
      .map((input) => (isNaN(input.value) || !input.value ? 0 : parseFloat(input.value)))
      .reduce((total, value) => total + value, 0);
    this.totalLoyerTarget.innerText = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(total_loyers);

    const charge_inputs = Array.prototype.slice.call(
      this.element.querySelectorAll(
        `div.nested-association:not([data-deleted="true"]) input[name*="[montant_des_provisions_sur_charges]"]`
      )
    );
    const total_charges = charge_inputs
      .map((input) => (isNaN(input.value) || !input.value ? 0 : parseFloat(input.value)))
      .reduce((total, value) => total + value, 0);
    this.totalChargeTarget.innerText = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(total_charges);

    this.totalLoyerChargeTarget.innerText = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(total_loyers + total_charges);

    const nested_loyer_rows = Array.prototype.slice.call(this.element.querySelectorAll('div.nested-association:not([data-deleted="true"])'));
    nested_loyer_rows.forEach((el) => {
      const row_loyer_target = el.querySelector("div.row-loyer-total");

      const row_loyer_inputs = Array.prototype.slice.call(el.querySelectorAll(`input[name*="[montant_du_loyer]"]`));
      const row_total_loyers = row_loyer_inputs
        .map((input) => (isNaN(input.value) || !input.value ? 0 : parseFloat(input.value)))
        .reduce((total, value) => total + value, 0);
      const row_charge_inputs = Array.prototype.slice.call(
        el.querySelectorAll(`input[name*="[montant_des_provisions_sur_charges]"]`)
      );
      const row_total_charges = row_charge_inputs
        .map((input) => (isNaN(input.value) || !input.value ? 0 : parseFloat(input.value)))
        .reduce((total, value) => total + value, 0);
      row_loyer_target.innerText = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(row_total_loyers + row_total_charges);
    });
  }

  update_multiple_leases(e) {
    e.target.id === "property_choose_bail_type_true" ? this.allow_multiple_leases() : this.prevent_multiple_leases();
  }

  allow_multiple_leases() {
    this.newLeaseTarget.removeAttribute("disabled");
    this.newLeaseTarget.classList.remove("hidden");
    this.update_total();
  }

  prevent_multiple_leases() {
    this.newLeaseTarget.setAttribute("disabled", "disabled");
    this.newLeaseTarget.classList.add("hidden");

    if (!this.partenaireContext) {
      // On appelle le nested_association_controller pour enlever correctement les éléments après le premier
      const nested_assocs = Array.prototype.slice.call(this.element.querySelectorAll('div.nested-association:not([data-deleted="true"]):not(:first-child)'));
      nested_assocs.forEach((el) => {
        el.dataset.deleted = true;
        this.application.getControllerForElementAndIdentifier(this.element, "nested-association").remove_child(el);
      });
      this.update_total();
    }
  }

  init_one_or_multiple_lease() {
    if (this.partenaireContext) {
      const bail_type_elem = this.element.querySelector("input[name='property[choose_bail_type]']:checked");
      bail_type_elem?.id === "property_choose_bail_type_true" ? this.allow_multiple_leases() : this.prevent_multiple_leases();
    } else {
      const bail_type_elem = this.element.querySelector("input[name=choose_bail_type]:checked");
      if (bail_type_elem?.id === "choose_bail_type_1") {
        this.prevent_multiple_leases();
      } else if (bail_type_elem?.id === "choose_bail_type_2") {
        this.allow_multiple_leases();
      }
    }
  }

  preDeleteRow(e) {
    const association = e.currentTarget.closest(".nested-association");
    if (association) {
      association.dataset.deleted = true;
      this.update_total();
    }
  }

  update_nb_mois_options() {
    let checked = this.element.querySelector(`input[name="${this.form_name}[furnished]"]:checked`);
    let month2options = this.element.querySelectorAll('[name$="[nb_mois_depot_garantie]"] option[value="2"]');
    if (checked.value == "true") {
      month2options.forEach((el) => {
        el.removeAttribute("disabled");
      });
    } else {
      month2options.forEach((el) => {
        el.setAttribute("disabled", "disabled");
        let select = el.closest("select");
        if (select.value == el.value) {
          select.value = "";
        }
      });
    }
  }

  update_type_charges_locatives() {
    let notFurnished = this.element.querySelector('[name$="[furnished]"][value="false"]');
    let bailUnique = this.element.querySelector('[name*="choose_bail_type"][type="radio"]');
    let chargeForfait = this.element.querySelector('[name$="[charge_calcul_type]"][value="1"]');
    const warningText = " - Non autorisé pour logement nu loué entièrement";
    chargeForfait.nextSibling.innerText = chargeForfait.nextSibling.innerText.replace(warningText, "");
    chargeForfait.disabled = false;
    if (notFurnished.checked && bailUnique.checked ) {
      chargeForfait.nextSibling.innerText += warningText;
      chargeForfait.disabled = true;
      chargeForfait.checked = false;
    }
  }

  toggle_plan_numerotation() {
    let bailUnique = this.element.querySelector('[name*="choose_bail_type"][type="radio"]');
    let leaseTable = this.leaseTableTarget;
    let boutonAjout = this.loyerTarget
    let labelList = leaseTable.querySelectorAll(".nested_association__name")
    if (bailUnique.checked) {
      this.planNumerotationTarget.classList.add("hidden");
      labelList.forEach((el) => {
        el.innerText = "Bail";
      });
    } else {
      this.planNumerotationTarget.classList.remove("hidden");
      labelList.forEach((el) => {
        el.innerText = "Chambre";
      });
    }
  }
}
