import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "infoModal",
  ];

  static values = {
    availableRooms: Number,
  };

  _infoModalController = null;

  connect() {
  }

  checkAvailability(e) {
    if (!this._infoModalController)
      this._infoModalController = this.infoModalTarget["MBA--modal--component"]
    if (this.availableRoomsValue < parseInt(e.currentTarget.value)) {
      this._infoModalController.open();
    }
  }
}
