import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.querySelectorAll("[data-input]").forEach((input) => {
      input.addEventListener(
        "input",
        ((e) => {
          let inputName = e.currentTarget.dataset.input;
          let exceptions = ["landlord_address", "property_address", "period_start", "period_end", "due_date"];
          if (!exceptions.includes(inputName)) {
            let output = this.element.querySelectorAll(`[data-output='${inputName}']`);
            output.forEach((el) => {
              el.innerText = e.currentTarget.value;
            });
          }
        }).bind(this)
      );
    });
  }

  formatAddress(event) {
    const regex = /([^\d]|^)\d{5}([^\d]|$)/g;
    const str = event.currentTarget.value;
    const newStr = str.replace(regex, "\n$&");
    // Input tel quel pour le champ qui n'a pas besoin de formatage
    if (event.currentTarget.dataset.input === "property_address") {
      this.element.querySelector(`[data-output='${event.currentTarget.dataset.input}']`).innerText = newStr;
      this.element.querySelector(`[data-output='property_address_unformatted']`).innerText = str;
    } else {
      // Input formaté pour les autres
      this.element.querySelector(`[data-output='${event.currentTarget.dataset.input}']`).innerText = newStr;
    }
  }

  updateDate(event) {
    const str = event.currentTarget.value;
    if (event.currentTarget.dataset.input === "period_start") {
      let frenchDate = new Intl.DateTimeFormat("fr-FR", { month: "long", year: "numeric" }).format(new Date(str));
      frenchDate = frenchDate[0].toUpperCase() + frenchDate.substring(1);

      this.element.querySelectorAll(`[data-output='month_year']`).forEach((output) => {
        output.innerText = str.slice(5, 7) + "/" + str.slice(0, 4);
      });
      this.element.querySelector(`[data-output='text_month_year']`).innerText = frenchDate;
    }
    this.element.querySelector(`[data-output='${event.currentTarget.dataset.input}']`).innerText = new Date(str).toLocaleDateString("fr-FR");
  }
}
