import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'documents',
        'documentsValidationText',
        'button'
    ];

    connect() {
        if (this.hasDocumentsTarget) {
            this.documentsTarget.addEventListener("change", (() => {
                this.validateFiles();
            }).bind(this))
        }
        this.validateFiles();
    }

    validateFiles() {
        if (this.hasDocumentsTarget) {

            if (this.documentsTarget.files.length > 5) {
                this.documentsValidationTextTarget.innerHTML = "<span style='color: red;'>Merci de sélectionner 5 fichiers maximum</span>";
                this.buttonTarget.disabled = true
            } else {
                this.documentsValidationTextTarget.innerHTML = '<span>5 fichiers maximum</span>'
                this.buttonTarget.disabled = false
            }
        }
    }
}
