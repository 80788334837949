import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["overlay", "previewImg", "previewVideo", "previewIframe", "filename", "currentIndex", "btnPrev", "btnNext"];

  _files = [];
  _currentIndex = 0;
  handleShortKeys = this.handleShortKeys.bind(this);

  connect() {
    // Load Files
    this._files = JSON.parse(this.element.dataset.files);

    const togglers = document.querySelectorAll(`[data-gallery="${this.element.id}"][data-toggle="gallery"]`);
    for (let i = 0; i < togglers.length; i++) {
      const toggler = togglers[i];
      const fileId = toggler.dataset.fileId;
      toggler.addEventListener("click", (e) => {
        this.open(e, fileId);
      });
    }
  }

  displayFile(e, fileId = null) {
    if (e && !fileId) {
      fileId = e.currentTarget.dataset.fileId;
    }
    const file = this._files.find((f) => f.id == fileId) || this._files[0];

    if (!file) return false;

    // Check file type
    let type = null;
    if (file.content_type.substr(0, 6) == "image/") type = "image";
    else if (file.content_type.substr(0, 6) == "video/") type = "video";
    else if (file.content_type == "application/pdf") type = "pdf";
    else if (file.content_type == "vr") type = "vr";

    this.previewImgTarget.style.display = "none";
    this.previewVideoTarget.style.display = "none";
    this.previewIframeTarget.style.display = "none";

    this.previewImgTarget.src = "";
    this.previewVideoTarget.src = "";
    this.previewIframeTarget.src = "";

    switch (type) {
      case "image":
        this.previewImgTarget.src = file.url;
        this.previewImgTarget.style.display = "block";
        break;
      case "video":
        this.previewVideoTarget.src = file.url;
        this.previewVideoTarget.style.display = "block";
        break;
      case "pdf":
      case "vr":
        this.previewIframeTarget.src = file.url;
        this.previewIframeTarget.style.display = "block";
        break;
    }

    this.filenameTarget.innerText = file.filename;

    this._currentIndex = this._files.findIndex((f) => f.id == fileId);
    this.currentIndexTarget.innerText = this._currentIndex + 1;

    if (this._currentIndex >= this._files.length - 1) this.btnNextTarget.setAttribute("disabled", true);
    else this.btnNextTarget.removeAttribute("disabled");

    if (this._currentIndex <= 0) this.btnPrevTarget.setAttribute("disabled", true);
    else this.btnPrevTarget.removeAttribute("disabled");

    const thumb = document.querySelector(`.thumbs .thumb[data-file-id="${fileId}"]`);
    document.querySelectorAll(".thumbs .thumb").forEach((el) => {
      el.classList.remove("btn--active");
    });
    thumb.classList.add("btn--active");
    thumb.focus();
  }

  prev(e) {
    e.stopPropagation();
    if (this._currentIndex <= 0) return false;
    this.displayFile(null, this._files[this._currentIndex - 1].id);
  }

  next(e) {
    e.stopPropagation();
    if (this._currentIndex >= this._files.length - 1) return false;
    this.displayFile(null, this._files[this._currentIndex + 1].id);
  }

  handleShortKeys(event) {
    switch (event.keyCode) {
      case 37: // Left arrow
        this.prev(event);
        break;
      case 39: // Right arrow
        this.next(event);
        break;
    }
    return;
  }

  open(e = null, fileId = null) {
    if (e) e.preventDefault();
    // Open specific file when opening gallery
    this.displayFile(null, fileId);
    this.element.style.display = "block";
    window.addEventListener("keydown", this.handleShortKeys);
  }

  close(e = null) {
    this.element.style.display = "none";
    window.removeEventListener("keydown", this.handleShortKeys);
  }
}
