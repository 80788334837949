import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["menu", "toggleMenuBtn", "appBody"]
    isBurgerMenuOpen = false

    connect() {
        this.updateBurgerMenu()
        window.addEventListener('resize', window.onWindowResize = this.updateBurgerMenu.bind(this))
    }

    disconnect() {
        window.removeEventListener('resize', window.onWindowResize)
    }

    /* Burger Menu */
    toggleMenu(event) {
        if (event)
            event.preventDefault()
        this.toggleMenuBtnTarget.classList.toggle("open")
        this.isBurgerMenuOpen = !this.isBurgerMenuOpen

        this.updateBurgerMenu()
    }

    updateBurgerMenu() {
        this.menuTargets.forEach(((menu) => {
            let w = window.innerWidth;
            if (w >= 768) {
                this.appBodyTarget.style.display = "flex"
                menu.classList.remove("hidden")
                menu.classList.add("flex")
            } else {
                if (this.isBurgerMenuOpen) {
                    menu.classList.remove("hidden")
                    menu.classList.add("flex")
                    this.appBodyTarget.style.display = "none"
                } else {
                    menu.classList.add("hidden")
                    menu.classList.remove("flex")
                    this.appBodyTarget.style.display = "flex"
                }
            }
        }).bind(this))
    }
}
