import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectorModal",
    "searchInput",
    "relative",
    "inputsContainer",
    "selectForm",
    "newForm",
  ];

  _currentIndex = -1;
  _bloc = null;
  _selectorModalController = null;

  connect() {
  }

  openRoommateSelector({params: {index}}) {
    if (!this._selectorModalController)
      this._selectorModalController = this.selectorModalTarget["MBA--modal--component"]
    this.setIndex(index);
    this._selectorModalController.open();
  }

  setIndex(index) {
    this._currentIndex = index;
    this._bloc = this.element.querySelector(`.roommate-bloc[data-index="${this._currentIndex}"]`);
  }

  openNewForm(e) {
    e.preventDefault();
    e.stopPropagation();
    this.selectFormTarget.style.display = "none";
    this.newFormTarget.style.display = "flex";
  }

  closeNewForm(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.selectFormTarget.style.display = "flex";
    this.newFormTarget.style.display = "none";
  }
}
