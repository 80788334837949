import {Controller} from "@hotwired/stimulus"
import {DirectUpload} from "@rails/activestorage"
import 'stylesheets/file_upload.scss';

export default class extends Controller {
    static targets = ["list", "fileInput",  "submitInput"]
    _files_loading = 0
    _direct_upload = true

    connect() {
        this._form = this.submitInputTarget.closest("form")
        this.fileInputTarget.addEventListener("change", this.fileSelected.bind(this))
    }

    fileSelected(event) {
        const input = event.target
        if (!input.files.length)
            return false
        Array.from(input.files).forEach(file => this.uploadFile(file))
        input.value = null
    }

    deleteFile(e) {
        const item = e.target.closest("li")
        const signed_id = item.dataset.value
        const input = this._form.querySelector(`input[value="${signed_id}"]`)
        input.remove()
        item.remove()
    }

    addFile() {
        this.fileInputTarget.click()
    }

    createListItem(file) {
        const item = document.createElement("li")
        const checkIcon = document.createElement("span")
        checkIcon.innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"h-4 w-4 text-primary mr-2\" fill=\"none\" viewBox=\"0 0 24 24\" stroke=\"currentColor\">\n" +
            "  <path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M5 13l4 4L19 7\" />\n" +
            "</svg>"
        checkIcon.classList.add("check-icon")

        const loaderIcon = document.createElement("span")
        loaderIcon.innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"h-4 w-4 text-light mr-2\" fill=\"none\" viewBox=\"0 0 24 24\" stroke=\"currentColor\">\n" +
            "  <path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12\" />\n" +
            "</svg>"
        loaderIcon.classList.add("loader-icon")

        const deleteBtn = document.createElement("button")
        deleteBtn.setAttribute("type", "button")
        deleteBtn.innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"h-5 w-5\" viewBox=\"0 0 20 20\" fill=\"currentColor\">\n" +
            "  <path fill-rule=\"evenodd\" d=\"M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z\" clip-rule=\"evenodd\" />\n" +
            "</svg>"
        deleteBtn.classList.add("delete-btn")
        deleteBtn.addEventListener("click", this.deleteFile.bind(this))

        const filenameSpan = document.createElement("span")
        filenameSpan.classList.add("flex-grow")
        filenameSpan.innerText = file.name

        const progressBar = document.createElement("div")
        progressBar.classList.add("progress-bar")

        item.appendChild(loaderIcon)
        item.appendChild(checkIcon)
        item.appendChild(filenameSpan)
        item.appendChild(deleteBtn)
        item.appendChild(progressBar)
        return item
    }

    uploadFile(file) {
        const url = this.fileInputTarget.dataset.directUploadUrl
        let uploader = new Uploader(file, url, this)
    }
}


class Uploader {
    constructor(file, url, controller) {
        this.upload = new DirectUpload(file, url, this)
        this.controller = controller

        this.item = this.controller.createListItem(file)
        this.controller.listTarget.appendChild(this.item)

        this.controller._files_loading++
        this.controller.submitInputTarget.disabled = true

        this.upload.create((error, blob) => {
            if (error) {
                // Handle the error
            } else {
                const hiddenField = document.createElement('input')
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("value", blob.signed_id);
                hiddenField.name = this.controller.fileInputTarget.name
                this.controller._form.appendChild(hiddenField)
                this.item.dataset.value = blob.signed_id
            }
            if (--this.controller._files_loading <= 0)
                this.controller.submitInputTarget.disabled = false
        })
    }

    directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener("progress",
            event => this.directUploadDidProgress(event))
    }

    directUploadDidProgress(event) {
        this.item.classList.add("upload-progress")
        this.item.querySelector(".progress-bar").style.width = `${event.loaded / event.total * 100}%`
        if (event.loaded >= event.total) {
            this.item.classList.remove("upload-progress")
            this.item.classList.add("upload-end")
        }
    }
}
