import { Controller } from "@hotwired/stimulus";
import { template } from "lodash";

export default class extends Controller {
  static targets = ["confirmButtons", "dateSelectedTemplate", "dateSelectedWrapper", "bookingWrapper", "noDateSelected", "modalDate", "hiddenSlotInput"];

  selectedSlot = undefined;
  #dateSelectedTemplate;

  connect() {
    this.#dateSelectedTemplate = template(this.dateSelectedTemplateTarget.innerHTML);
  }

  selectSlot({ currentTarget, params }) {
    this.updateSelectedSlot(params);
    this.selectedSlot = this.selectedSlot !== currentTarget && currentTarget;
    this.updateButtonStatus();
    this.hiddenSlotInputTarget.value = currentTarget.value;
  }

  updateButtonStatus() {
    this.confirmButtonsTargets.forEach((btn) => {
      if (this.selectedSlot) {
        btn.classList.remove("button--cta-disabled");
      }
    });
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();

    return div.firstChild;
  }

  updateSelectedSlot(params) {
    const { slotStart, slotName } = params;
    const itemData = {
      date: this.formatDate(slotStart),
      pick: slotName,
    };

    this.modalDateTarget.innerHTML = `${this.formatDate(slotStart)} • ${slotName}`;

    // Removes the previous selectedSlot item before adding the new one
    this.hasDateSelectedWrapperTarget &&
      this.dateSelectedWrapperTargets.forEach((i) => {
        i.remove();
      });

    const item = this.createElementFromHTML(this.#dateSelectedTemplate(itemData));
    this.bookingWrapperTarget.appendChild(item);
    this.noDateSelectedTarget.classList.add("hidden");
  }

  formatDate(inputDateStr) {
    const inputDate = new Date(inputDateStr);
    const options = { weekday: "long", day: "numeric", month: "long" };
    const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(inputDate);
    return formattedDate[0].toUpperCase() + formattedDate.substring(1);
  }
}
