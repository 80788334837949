import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'selectLineTypeInput',
    'leaseRentSelect',
    'expenseSelect',
    'propertyRentPaymentSelect',
    'leaseTenantPaymentSelect',
    'transferRefundSelect',
    'transferDgSelect',
    'otherWithoutResourceInput'
  ];
  selectInputController

  initialize() {
    this.selectInputController = this.selectLineTypeInputTarget['form--collection-select--component'];
    this.selectedLineType = null;
    this.matchLineTypeTargets = {
      "loyer": this.hasLeaseRentSelectTarget ? this.leaseRentSelectTarget : null,
      "charges": this.hasLeaseRentSelectTarget ? this.leaseRentSelectTarget : null,
      "depot_de_garantie": this.hasLeaseRentSelectTarget ? this.leaseRentSelectTarget : null,
      "honoraire_mel": this.hasLeaseRentSelectTarget ? this.leaseRentSelectTarget : null,
      "frais": this.hasExpenseSelectTarget ? this.expenseSelectTarget : null,
      "virement_proprio": this.hasPropertyRentPaymentSelectTarget ? this.propertyRentPaymentSelectTarget : null,
      "virement_locataire": this.hasLeaseTenantPaymentSelectTarget ? this.leaseTenantPaymentSelectTarget : null,
      "virement_refund_credit": this.hasTransferRefundSelectTarget ? this.transferRefundSelectTarget : null,
      "virement_depot_de_garantie": this.hasTransferDgSelectTarget ? this.transferDgSelectTarget : null,
      "other_without_resource": this.hasOtherWithoutResourceInputTarget ? this.otherWithoutResourceInputTarget : null,
    };
  }

  displayRelatedResource(e) {
    if (!this.getSelectInputController())
      return;
    const selectedOption = this.getSelectInputController().value;
    if (selectedOption == this.selectedLineType)
      return;
    // Hide and reset other select inputs
    Object.entries(this.matchLineTypeTargets).forEach(([key, val]) => {
      if (key == selectedOption || val == null)
        return;
      if (val.type == "text") {
        val.value = "";
      } else {
        let selectedInputs = val['form--collection-select--component'].inputListTarget.querySelectorAll('input');
        selectedInputs.forEach(elem => {
          val['form--collection-select--component'].removeValue({ value: elem.value });
          val['form--collection-select--component'].updatePrompt();
        });
      }
      let otherWrapper = this.element.querySelector(`[data-wrapper-for='${val.dataset.journalEntryFormTarget}']`);
      otherWrapper.classList.add("hidden");
    });

    let wrapper = this.element.querySelector(`[data-wrapper-for='${this.matchLineTypeTargets[selectedOption].dataset.journalEntryFormTarget}']`);
    wrapper.classList.remove("hidden");
  }

  displayRelatedEntryType(e) {
    if (!this.getSelectInputController())
      return;
    const selectedOption = this.getSelectInputController().value;
    // Hide and reset other select inputs
    this.element.querySelectorAll('[data-journal-entry-select-for-entry]').forEach(element => {
      if (!element.classList.contains("hidden"))
        element.classList.add("hidden");
      let selectComponent = element.querySelector("[data-controller='form--collection-select--component']");
      selectComponent['form--collection-select--component'].inputListTarget.querySelectorAll('input').forEach(elem => {
        selectComponent['form--collection-select--component'].removeValue({ value: elem.value });
        selectComponent['form--collection-select--component'].updatePrompt();
      });
    });

    let journalEntryTypeWrapper = this.element.querySelector(`[data-journal-entry-select-for-entry='${selectedOption}']`);
    if (journalEntryTypeWrapper)
      journalEntryTypeWrapper.classList.remove("hidden");
  }

  getSelectInputController() {
    return this.selectInputController || this.selectLineTypeInputTarget['form--collection-select--component']
  }
}
