import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["missingReasonToggle", "missingReasonSelect", "missingReasonOther", "missingReasonForm", "editReasonButton", "missingReasonText"]

    initialize() {
        this.onMissingReasonToggled = this.onMissingReasonToggled.bind(this)
        this.onMissingReasonChanged = this.onMissingReasonChanged.bind(this)
        this.onEditReasonClicked = this.onEditReasonClicked.bind(this)
        this.toggleReasonBlock = this.toggleReasonBlock.bind(this)
        this.onOtherReasonChanged = this.onOtherReasonChanged.bind(this)
    }

    connect() {
        if (this.hasMissingReasonToggleTarget) {
            this.missingReasonToggleTarget.addEventListener("change", this.onMissingReasonToggled)
            this.missingReasonSelectTarget.addEventListener("change", this.onMissingReasonChanged)
            if (this.hasEditReasonButtonTarget) {
                this.editReasonButtonTarget.addEventListener("click", this.onEditReasonClicked)
            }
            this.missingReasonOtherTarget.addEventListener("input", this.onOtherReasonChanged)
        }
    }

    disconnect() {
        if (this.hasMissingReasonToggleTarget) {
            this.missingReasonToggleTarget.removeEventListener("change", this.onMissingReasonToggled)
            this.missingReasonSelectTarget.removeEventListener("change", this.onMissingReasonChanged)
            if (this.hasEditReasonButtonTarget) {
                this.editReasonButtonTarget.removeEventListener("click", this.onEditReasonClicked)
            }
            this.missingReasonOtherTarget.removeEventListener("input", this.onOtherReasonChanged)
        }
    }

    onMissingReasonToggled(e) {
        const el = e.currentTarget
        this.toggleReasonBlock(el.checked)
        if (!el.checked) {
            this.missingReasonFormTarget.requestSubmit()
        }
    }

    onMissingReasonChanged(e) {
        const el = e.currentTarget
        if (el.value == "Autre...") {
            this.missingReasonOtherTarget.classList.remove("hidden")
            this.missingReasonOtherTarget.required = true
        } else {
            this.missingReasonOtherTarget.classList.add("hidden")
            this.missingReasonOtherTarget.required = false
        }
    }

    onEditReasonClicked(e) {
        this.missingReasonTextTarget.remove()
        this.toggleReasonBlock(true)
    }

    toggleReasonBlock(display) {
        if (display == true) {
            this.missingReasonFormTarget.classList.remove("hidden")
            this.missingReasonSelectTarget.required = true
        } else {
            this.missingReasonFormTarget.classList.add("hidden")
            this.missingReasonSelectTarget.required = false
            this.missingReasonOtherTarget.required = false
            // Clear fields
            this.missingReasonSelectTarget.selectedIndex = -1
            this.missingReasonOtherTarget.value = ""
        }
    }

    onOtherReasonChanged(e) {
        // Trim input
        const trim_val = this.missingReasonOtherTarget.value?.trim()
        if (trim_val == "")
            this.missingReasonOtherTarget.value = ""
    }
}
