import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "wrapper"];
  static values = { index: Number, min: Number, max: Number, partenaire: String };

  _count = 0;
  addedElem = null;

  connect() {
    this.update_indexes();
    if (this.minValue > 0) {
      for (let i = 0; i < this.minValue; i++) this.add_association();
    }
  }

  add_association(event) {
    if (event) event.preventDefault();
    if (this.maxValue && !(this._count < this.maxValue)) return false;
    this.addedElem = document.createElement("div");
    this.addedElem.dataset.newRecord = "true";
    this.addedElem.classList.add("nested-association");
    var content = this.templateTarget.innerHTML.replace(/RANDOM_INDEX/g, (Math.random() * 1000000).toString());
    content = content.replace(/NEW_INDEX_1/g, this.indexValue + 1);
    content = content.replace(/NEW_INDEX/g, this.indexValue);
    this.addedElem.innerHTML = content;
    this.wrapperTarget.appendChild(this.addedElem);
    this.indexValue += 1;
    this._count++;
  }

  getAddedElem() {
    return this.addedElem;
  }

  remove_last_association(event) {
    event.preventDefault();
    const children = this.wrapperTarget.querySelectorAll(':scope > div:not([data-deleted="true"])');
    const lastChild = children.length ? children[children.length - 1] : null;
    if (lastChild) this.remove_child(lastChild);
  }

  remove_association(event) {
    if (event) event.preventDefault();
    const btn = event.currentTarget;
    const child = btn.closest(".nested-association");
    if (child) this.remove_child(child);
  }

  remove_all_associations() {
    this.element.querySelectorAll(".nested-association").forEach(
      ((el) => {
        this.remove_child(el);
      }).bind(this)
    );
    this._count = 0;
  }

  remove_child(child) {
    if (child.dataset.newRecord == "true") {
      child.remove();
    } else {
      child.querySelector("input[name*='_destroy']").value = 1;
      child.style.display = "none";
      child.dataset.deleted = "true";
    }
    this.indexValue -= 1;
    this._count--;
    this.update_indexes();
  }

  update_indexes() {
    const associations = this.element.querySelectorAll(".nested-association");
    let index = 0;
    associations.forEach((elem) => {
      if (elem.querySelector("input[name*='_destroy']") && elem.querySelector("input[name*='_destroy']").value == 1) return;
      let content = elem.innerHTML;
      if (content.indexOf("NEW_INDEX") > -1) {
        content = content.replace(/NEW_INDEX_1/g, index + 1);
        content = content.replace(/NEW_INDEX/g, index);
        elem.innerHTML = content;
      }

      elem.querySelectorAll(".nested-association__index").forEach((el) => {
        el.innerText = index;
      });
      elem.querySelectorAll(".nested-association__index-1").forEach((el) => {
        el.innerText = index + 1;
      });
      index++;
    });
  }
}
