import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {

  }

  toggleRoomTasks(e) {
    const input = e.currentTarget
    const checked = input.checked
    // Get all unchecked children items
    const items = input.closest('.room-items').querySelectorAll('input[name="form_object[maintenance_task_item_ids][]"]:not(:disabled)')
    items.forEach(item => {
      item.checked = checked
    })
  }

  toggleItemTask(e) {
    const input = e.currentTarget
    const roomInput = input.closest('.room-items').querySelector('input[name="room_check"]')
    const allItemsChecked = input.closest('.room-items').querySelectorAll('input[name="form_object[maintenance_task_item_ids][]"]:not(:disabled):not(:checked)').length == 0
    roomInput.checked = allItemsChecked
  }
}
