import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectInput", "schoolInput"];
  static values = { studentStatesId: String, personalSituationInitial: String };

  selectInputController;

  initialize() {
    this.selectInputController = this.selectInputTarget["form--collection-select--component"];
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  connect() {
    this.toggleSchoolInput(+this.personalSituationInitialValue)
  }

  toggleSchoolInput(value) {
    const studentStatesIdsArray = JSON.parse(this.studentStatesIdValue) || [];
    this.schoolInputTarget.classList.toggle("hidden", !studentStatesIdsArray.includes(value));
  }

  onSelectChange() {
    const selectInputController = this.getSelectInputController();
    if (!selectInputController) return;
    const selectedOption = +selectInputController.value;
    this.toggleSchoolInput(selectedOption)
  }

  getSelectInputController() {
    return this.selectInputController || this.selectInputTarget["form--collection-select--component"];
  }
}
