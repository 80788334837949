import {Controller} from "@hotwired/stimulus"
import 'stylesheets/accordion.scss';

export default class extends Controller {
    static targets = ["content", "caret"]
    static classes = ["opening"]

    connect() {
        this.contentTarget.classList.add("hidden")
        this.element.classList.add("accordion")
      }

      toggle() {
        if (this.hasOpeningClass) {
          this.element.classList.toggle(this.openingClass)
        }
        this.contentTarget.classList.toggle("hidden")
        this.caretTargets.forEach(caret => {
          if (!caret) return;
          if (caret.style.transform === "rotate(0deg)") {
            caret.style.transform = "rotate(90deg)"
          } else if (caret.style.transform === "rotate(90deg)") {
            caret.style.transform = "rotate(0deg)"
          }
        })
      }
}
