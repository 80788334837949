import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "formContainer", "chat", "loader", "ticketNotice"];

  initialize() {
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.scrollDown = this.scrollDown.bind(this);
  }

  connect() {
    if (this.hasFormTarget) {
      this.formTarget.addEventListener("submit", this.onFormSubmit);
      document.addEventListener("turbo:before-stream-render", this.resetForm);
      document.addEventListener("turbo:load", this.scrollDown);
      this.chatTarget.addEventListener("DOMNodeInserted", this.scrollDown);
    }
  }

  disconnect() {
    this.formTarget.removeEventListener("submit", this.onFormSubmit);
    document.removeEventListener("turbo:before-stream-render", this.resetForm);
    document.removeEventListener("turbo:load", this.scrollDown);
    this.chatTarget.removeEventListener("DOMNodeInserted", this.scrollDown);
  }

  resetForm() {
    this.loaderTarget.classList.add("hidden");
    this.formTarget.reset();
    this.formTarget.querySelectorAll("[type='submit']").forEach((button) => {
      button.removeAttribute("disabled");
    });
  }

  scrollDown() {
    let page = this.element.closest(".page");
    if (!page) return;
    page.scrollTop = page.scrollHeight;
  }

  onFormSubmit(e) {
    this.chatTarget.append(this.loaderTarget);
    this.loaderTarget.classList.remove("hidden");
    this.formTarget.querySelectorAll("[type='submit']").forEach((button) => {
      button.setAttribute("disabled", "disabled");
    });
  }

  reopen(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.formContainerTarget.classList.remove("hidden");
    this.ticketNoticeTarget.classList.add("hidden");
  }
}
