import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["primaryTranslatingFrame", "secondaryTranslatingFrame", "validateBtn", "refuseBtn", "refuseFrame", "validateFrame", "ticketBloc"];

  // Gestion carousel validation candidatures
  triggerCarousel(e) {
    if (e) e.preventDefault();

    this.primaryTranslatingFrameTarget.classList.add("swipe-out");
    this.secondaryTranslatingFrameTarget.classList.add("swipe-in");

    if (e.currentTarget === this.validateBtnTarget) {
      this.validateFrameTarget.classList.replace("hidden", "flex");
    }
    if (e.currentTarget === this.refuseBtnTarget) {
      this.refuseFrameTarget.classList.replace("hidden", "flex");
    }
  }

  cancel() {
    this.primaryTranslatingFrameTarget.classList.replace("swipe-out", "swipe-back-in");
    this.secondaryTranslatingFrameTarget.classList.replace("swipe-in", "swipe-back-out");
    setTimeout(() => {
      this.primaryTranslatingFrameTarget.classList.remove("swipe-back-in");
      this.secondaryTranslatingFrameTarget.classList.remove("swipe-back-out");
      this.validateFrameTarget.classList.replace("flex", "hidden");
      this.refuseFrameTarget.classList.replace("flex", "hidden");
    }, 600);
  }

  // Ouverture bloc ticket
  toggleTicketBloc(e) {
    if (e) e.preventDefault();

    const ticketBloc = this.ticketBlocTarget;
    if (ticketBloc.classList.contains("open")) {
      ticketBloc.classList.remove("open");
    } else {
      ticketBloc.classList.add("open");
    }
  }
}
