import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

  initialize() {
    this.onRenderFrame = this.onRenderFrame.bind(this)
    this.refreshResults = this.refreshResults.bind(this)
  }

  connect() {
    // Update map results when rendered
    this._searchController = this.element.closest('#search-container')['catalog-search']
    this.refreshResults()
    document.addEventListener("turbo:frame-render", this.onRenderFrame)
  }

  disconnect() {
    document.removeEventListener("turbo:frame-render", this.onRenderFrame)
  }

  onRenderFrame(e) {
    if (e.target != this.element)
      return
    this.refreshResults()
  }

  refreshResults() {
    if (this._searchController._ready)
      this._searchController.refreshResults()
  }
}
