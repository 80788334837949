import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "submit"]

  display(event) {
    const elementTarget = document.querySelector(`input[name="form[guarantor]"]:checked`)
    if (elementTarget.value == "none,0") this.checkSubmitNoGuarantor(event)
  }

  checkSubmitNoGuarantor(event) {
    event.preventDefault()
    const elementTarget = document.querySelector(`input[name="form[guarantor]"]:checked`)
    if (elementTarget) {
      if (event.submitter.name === "modalSubmit") {
        event.currentTarget.submit()
        return true
      }
      const guarantorValue = elementTarget.value;
      if (guarantorValue !== "none,0") {
        return true
      } else {
        const _modalController = this.modalTarget["MBA--modal--component"]
        _modalController.open();
        setTimeout(() => {
          this.submitTarget.removeAttribute("disabled")
        }, 1);
        return false
      }
    }
  }
}
