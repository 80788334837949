import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    setTimeout(() => {
      this.modalTarget.classList.remove('open');
      this.modalTarget.classList.add('hide');
    }, 4000);
  }
}
