document.addEventListener("turbo:submit-start",
  ({target: formElement, detail: {formSubmission}}) => {
    // method : GET = 0, POST = 1, PUT = 2, PATCH = 3, DELETE = 4
    if (formSubmission.method != 0)
      return true
    const forcesTurboStream = formElement.getAttribute("data-turbo") == "true"
    if (forcesTurboStream) {
      formSubmission.fetchRequest.headers["Accept"] = "text/vnd.turbo-stream.html"
      const searchParams = new URLSearchParams(new FormData(formElement))
      const queryString = searchParams.toString()
      const url = new URL(window.location);
      url.searchParams = searchParams;
      url.search = queryString
      window.history.pushState({}, '', url);
    }
  }
)