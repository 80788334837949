import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = []

    _form = null

    connect() {
        this._form = this.element
        this._form.addEventListener("submit", this.onSubmit)
    }

    onSubmit(e) {
        let form = e.target
        // Clear errors
        let errorDivs = form.querySelectorAll(".help.is-danger")
        if (errorDivs) {
            for (let i = 0; i < errorDivs.length; i++) {
                errorDivs[i].remove()
            }
        }
        let errorFields = form.querySelectorAll(".is-danger")
        if (errorFields) {
            for (let i = 0; i < errorFields.length; i++) {
                errorFields[i].classList.remove("is-danger")
            }
        }
    }

    onError(event) {
        let [{errors = []}, status, xhr] = event.detail;
        Object.keys(errors).forEach((key) => {
            let field = this._form.querySelector(`[name^="${this._form.dataset.name}[${key}"]`)
            field.classList.add('is-danger');

            let errorDiv = document.createElement("div")
            errorDiv.className = "help is-danger"
            errorDiv.innerText = errors[key]

            field.after(errorDiv)
        });
    }
}
