import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "totalChargesDisplay",
    "totalChargesInput",
    "formBlock",
    "submitButton",
    "regulTrueDiv",
    "regulFalseDiv",
    "regulTrueCheckbox",
    "regulFalseCheckbox",
    "regulPresenceInput",
  ];

  connect() {
    if (this.regulPresenceInputTarget.value) {
      this.regulPresenceInputTarget.value == "true" && this.regulTrueDivTarget.dispatchEvent(new Event("click"));
      this.regulPresenceInputTarget.value == "false" && this.regulFalseDivTarget.dispatchEvent(new Event("click"));
      this.updateTotalCharges();
    }
    this.disableInputsOnLoad();
  }

  disableInputsOnLoad() {
    const inputsToDisable = this.element.querySelectorAll("input[data-input-disabled-on-load='true']");
    inputsToDisable.forEach((input) => {
      const button = input.nextSibling;
      // Ne fonctionne pas sans le timeout
      setTimeout(() => {
        button.dispatchEvent(new Event("click"));
      }, 1);
    });
  }

  getToggleController(element) {
    return element.querySelector("[data-controller='dropdown--component']")["dropdown--component"];
  }

  toggleChargesForm(event) {
    const optionClicked = event.params.boolean;

    this.regulTrueCheckboxTarget.checked = optionClicked;
    this.regulFalseCheckboxTarget.checked = !optionClicked;
    this.regulTrueDivTarget.classList.toggle("border-purple-main", optionClicked);
    this.regulFalseDivTarget.classList.toggle("border-purple-main", !optionClicked);

    // On inscrit la valeur dans l'hidden input
    this.regulPresenceInputTarget.value = optionClicked;

    // On toggle le submit seulement si "no regul" est séléctionné
    if (this.regulFalseCheckboxTarget.checked) {
      this.submitButtonTarget.disabled = this.hasSubmitButtonTarget && false;
    } else {
      this.submitButtonTarget.disabled = this.hasSubmitButtonTarget && true;
    }

    // On display le bloc de regul
    this.hasFormBlockTarget && this.formBlockTarget.classList.toggle("hidden", !optionClicked);
  }

  check(option) {
    if (option) {
      this.regulTrueCheckboxTarget.checked = true;
      this.regulFalseCheckboxTarget.checked = false;
    } else {
      this.regulFalseCheckboxTarget.checked = false;
      this.regulTrueCheckboxTarget.checked = false;
    }
    this.regulPresenceInputTarget.value = option;
  }

  toggleChargeInput(event) {
    const elementToDisableName = event.currentTarget.dataset.elementToDisable;
    const inputToDisable = this.element.querySelector(`[name="form[${elementToDisableName}]"]`);

    if (inputToDisable) {
      inputToDisable.value = "";
      inputToDisable.toggleAttribute("disabled");
      this.updateTotalCharges();
    }
  }

  updateTotalCharges() {
    const activeInputs = this.element.querySelectorAll("input.inputForTotalCharges:not([disabled])");
    let total = 0;
    activeInputs &&
      activeInputs.forEach((input) => {
        if (input.value.trim() === "" || isNaN(input.value)) {
          return;
        }
        total += parseFloat(input.value);
      });
    const formattedCurrency = new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
    }).format(total);

    if (this.hasTotalChargesDisplayTarget) {
      this.totalChargesDisplayTarget.textContent = formattedCurrency;
    }
    // On active le bouton de submit
    if (total > 0) {
      this.submitButtonTarget.disabled = this.hasSubmitButtonTarget && false;
    } else {
      this.submitButtonTarget.disabled = this.hasSubmitButtonTarget && true;
    }
  }

  submitForm() {
    this.submitButtonTarget.form.submit();
  }
}
