import {Controller} from "@hotwired/stimulus"

require("stylesheets/password_checker.scss");

export default class extends Controller {
    static targets = ["meter"]

    connect() {
        this.meterTarget.innerHTML = "<div class='indicator grid grid-cols-8 gap-1 items-center'>" +
            "<div class='bar'></div>" +
            "<div class='bar'></div>" +
            "<div class='bar'></div>" +
            "<div class='bar'></div>" +
            "<div class='bar'></div>" +
            "<div class='col-span-3 text-2xs pl-4 help'>Mot de passe invalide</div>" +
            "</div>"
        this.meterTarget.style.display = "none";
    }

    check(e) {
        const password = e.target.value;
        if (password)
            this.meterTarget.style.display = "block";
        else
            this.meterTarget.style.display = "none";

        let score = 0;
        let scores = {
            0: {help: "invalide", color: "#DDD"},
            1: {help: "très faible", color: "#D34E24"},
            2: {help: "faible", color: "#F28123"},
            3: {help: "moyen", color: "#F7F052"},
            4: {help: "fort", color: "#06D6A0"},
            5: {help: "robuste", color: "#32936F"},
        }

        if (/(?=^.{6,}$)/.test(password)) {
            if (/(?=^.{8,}$)/.test(password))
                score++
            if (/(?=.*[A-Z].*[A-Z])/.test(password))
                score++
            if (/(?=.*[!@#$&*])/.test(password))
                score++
            if (/(?=.*[0-9].*[0-9])/.test(password))
                score++
            if (/(?=.*[a-z].*[a-z].*[a-z])/.test(password))
                score++
        }

        let bars = this.meterTarget.querySelectorAll(".bar")
        for (let i = 0; i < bars.length; i++) {
            if (i >= score)
                bars[i].style.backgroundColor = scores[0].color
            else
                bars[i].style.backgroundColor = scores[score].color
        }
        this.meterTarget.querySelector(".help").innerText = `Mot de passe ${scores[score].help}`
    }
}
