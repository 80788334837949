import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["turboFrame"]

  lotChanged(e) {
    const value = e.currentTarget['form--collection-select--component'].value
    this.turboFrameTarget.setAttribute("src", this.turboFrameTarget.dataset.src + `?lot_uid=${value}`)
  }
}
