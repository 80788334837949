import {Controller} from "@hotwired/stimulus"

/**
 * Affiche ou cache les champs lies au garant de type physique dans les formulaires de l espace partenaire
 **/
export default class extends Controller {
  static targets = ["physicalFields", "personalSituationInput"]
  static values = { physicals: Array }

  typeChanged(e) {
    const value = e.target.value
    const valueId = value.length > 0 ? value[0].value : null
    if (valueId) {
      if (this.physicalsValue.includes(valueId)) {
        this.physicalFieldsTarget.classList.remove("hidden")
      } else {
        this.physicalFieldsTarget.classList.add("hidden")
      }
    } else {
      this.physicalFieldsTarget.classList.add("hidden")
    }
    this.personalSituationInputTarget["form--collection-select--component"].clearValue()
  }
}
