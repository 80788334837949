import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["canvas", "loader"]
    static values = {
        data: Object,
        options: Object
    }

    chart = null

    connect() {
        document.addEventListener('turbo:before-fetch-request', () => {
            this.displayLoader()
        })
        import("chart.js/auto").then((chart => {
            this.chart = chart.default;
            this.initChart()
        }).bind(this));
    }


    initChart() {
        let options = this.optionsValue

        this.loaderTarget.classList.add("hidden")
        new this.chart(this.canvasTarget.getContext('2d'), {
            type: 'bar',
            data: this.dataValue,
            options: options
        })
    }

    displayLoader() {
        this.loaderTarget.classList.remove("hidden")
    }
}
