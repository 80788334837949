import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = []

    connect() {
        // if(!this.fileInputTarget)
        //     throw "[stimulus][droppable_upload] Missing fileInput target"
        //
        // this.dropzoneTarget.addEventListener("click", this.openFileBrowser.bind(this))
        // this.dropzoneTarget.addEventListener("dragover", this.onDragOver.bind(this))
        // this.dropzoneTarget.addEventListener("dragenter", this.onDragEnter.bind(this))
        // this.dropzoneTarget.addEventListener("dragleave", this.onDragLeave.bind(this))
        // this.dropzoneTarget.addEventListener("drop", this.onDrop.bind(this))
    }

    openFileBrowser(e) {
        if (!this.fileInputTarget.value)
            this.fileInputTarget.click()
    }
    //
    // onDragOver(e) {
    //     e.preventDefault()
    // }
    //
    // onDragEnter(e) {
    //     this.dropzoneTarget.classList.add("dragover")
    // }
    //
    // onDragLeave(e) {
    //     this.dropzoneTarget.classList.remove("dragover")
    // }
    //
    // onDrop(e) {
    //     e.preventDefault()
    //     this.dropzoneTarget.classList.remove("dragover")
    //     this.fileInputTarget.files = e.dataTransfer.files
    //     this.dropzoneTarget.click()
    //     if ("createEvent" in document) {
    //         let evt = document.createEvent("HTMLEvents");
    //         evt.initEvent("change", false, true);
    //         this.fileInputTarget.dispatchEvent(evt);
    //     } else {
    //         this.fileInputTarget.fireEvent("onchange");
    //     }
    // }
}
